import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';

class LoginRedirect extends Component {
    componentDidMount() {
        //debugger
        let urlParams = (new URL(document.location)).searchParams;

        let token = urlParams.get('token');
        
        console.log('token', token);
        if (token === null) {

            const serviceUrl = 
                window.location.hostname.includes("staging") || window.location.hostname == 'localhost' || window.location.hostname.includes("testing")
                    ? "staging.entrecloud.se" : "entrecloud.se"

            const loginUrl = `https://services.${serviceUrl}/login`;
            const title = encodeURIComponent('Integrations Engine');
            let returnUrl = window.location.origin+'#';
            returnUrl = btoa(returnUrl); // and Base64
            const navUrl = `${loginUrl}?title=${title}&returnUrl=${returnUrl}`;
            window.location.href = navUrl;

        }
        //debugger
        this.props.userLogin(token);
    }

    render() {
        return (
            <></>
        );
    }
};

export default connect(undefined, { userLogin })(LoginRedirect);